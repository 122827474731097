<template>
  <div>
    <selectGarage
      :visible.sync="selectGarageDialog"
      :pitch_item.sync="selectGarageItem"
    />

    <el-drawer
      title="关联该AGV到指定车库"
      :visible.sync="drawer"
      :direction="'rtl'"
      :before-close="handleClose"
    >
      <div style="width: 90%; margin: 0 auto">
        <el-descriptions
          title="当前选中的车库信息"
          v-if="selectGarageItem"
          :column="2"
          direction="vertical"
        >
          <el-descriptions-item label="车库名">{{
            selectGarageItem.info_name
          }}</el-descriptions-item>
          <el-descriptions-item label="车库ID">{{
            selectGarageItem.garage_id
          }}</el-descriptions-item>
          <el-descriptions-item label="居住地">{{
            selectGarageItem.info_address
          }}</el-descriptions-item>
        </el-descriptions>

        <el-button @click="selectGarageDialog = true" v-if="!selectGarageItem"
          >选择需要关联的车库</el-button
        >
        <div class="layoutBorder">
          <el-button @click="selectGarageDialog = true" v-if="selectGarageItem"
            >重新选择需要关联的车库</el-button
          >
        </div>

        <el-divider></el-divider>

        <el-button @click="sumbit()" v-if="selectGarageItem">确认无误并关联</el-button>
      </div>
    </el-drawer>

    <infoHeader :content_name="'AMR详细信息'" />

    <!-- <el-divider content-position="left">AGV信息</el-divider> -->

    <el-descriptions title="" direction="vertical" :column="3" v-if="querydata">
      <el-descriptions-item label="设备编号" :span="1">{{
        querydata.device_number
      }}</el-descriptions-item>
      <el-descriptions-item label="设备ID">{{
        querydata.equipment_id
      }}</el-descriptions-item>
      <el-descriptions-item label="设备序列号" :span="1">{{
        querydata.serial_number
      }}</el-descriptions-item>
      <el-descriptions-item label="所属产品类型" :span="1">{{
        querydata.product.product_name
      }}</el-descriptions-item>
      <el-descriptions-item label="机器人状态">{{
        querydata.agv_status
      }}</el-descriptions-item>
      <el-descriptions-item label="创建时间">{{
        querydata.create_time
      }}</el-descriptions-item>

      <el-descriptions-item label="最近一次信息更新时间">{{
        querydata.update_time
      }}</el-descriptions-item>

      <el-descriptions-item label="生产商" :span="1">{{
        querydata.manufacturer
      }}</el-descriptions-item>
      <el-descriptions-item label="软件版本" :span="1">{{
        querydata.software.product_name
      }}</el-descriptions-item>

    </el-descriptions>

    <el-divider content-position="left">机器人关联信息</el-divider>

    <el-tabs v-model="tabActiveName" @tab-click="tabHandleClick">
        <el-tab-pane label="运营变更记录" name="entrance">
          <entrance v-if="show_entrance" :garage_id="itemData.garage_id" />
        </el-tab-pane>
        <el-tab-pane label="维护记录" name="lot">
          <lot v-if="show_lot" :garage_id="itemData.garage_id" />
        </el-tab-pane>
        <el-tab-pane label="保修记录" name="agv">
          <agv v-if="show_agv" :garage_id="itemData.garage_id" />
        </el-tab-pane>
        <el-tab-pane label="检查记录" name="equipment"
          ><equipmentlibrary v-if="show_equipmentlibrary" :garage_id="itemData.garage_id"
        /></el-tab-pane>
        <el-tab-pane label="故障记录" name="monitoring"
          ><monitoring v-if="show_monitoring" :garage_id="itemData.garage_id"
        /></el-tab-pane>
        <el-tab-pane label="证书管理" name="cloud_clinet"
          ><cloudsetting v-if="show_cloudsetting" :garage_id="itemData.garage_id"
        /></el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
// 载入接口
import {
  agv_info_get,
  agv_record_get,
  agv_licence_get,
  agv_status_link_garage_update,
  agv_licence_create,
} from "@/api/manage/operation/agv/agv.js";

import selectGarage from "@/components/selectGarage.vue";

export default {
  name: "start",
  data() {
    return {
      agv_status: null,
      agv_status_dict: [
        {
          value: 0,
          label: "待装配",
        },
        {
          value: 1,
          label: "装配中",
        },
        {
          value: 2,
          label: "已装配",
        },
        {
          value: 3,
          label: "测试中",
        },
        {
          value: 4,
          label: "待出厂",
        },
        {
          value: 5,
          label: "服役中",
        },
        {
          value: 6,
          label: "已回厂",
        },
        {
          value: 7,
          label: "销毁",
        },
        {
          value: 8,
          label: "迁库",
        },
        {
          value: 9,
          label: "检修中",
        },
      ],
      querydata: null,
      recorddata: [],
      licencedata: [],
      ids: null,
      drawer: false,
      selectGarageDialog: false,
      selectGarageItem: null,
      tabActiveName: "entrance", //选项卡配置
    };
  },
  components: {
    selectGarage,
  },
  created() {
    this.equipment_id = this.$route.query.ids;
    this.getQueryData();
    this.getOperatingRecordData();
    this.getLicenceData();
  },
  methods: {
    certAdd() {
      this.$confirm("是否确定增加证书？")
        .then(() => {
          agv_licence_create({ equipment_id: this.$route.query.ids }).then((res) => {
            if (res.code == 200) {
              this.$message.success("新增成功");
              this.getQueryData();
              this.getOperatingRecordData();
              this.getLicenceData();
            }else{
              this.$message.info("新增失败")
            }
          });
        })
        .catch(() => {
          this.$message.info("取消");
        });
    },
    sumbit() {
      this.$http(
        agv_status_link_garage_update({
          equipment_id: this.equipment_id,
          garage_id: this.selectGarageItem.garage_id,
        }),
        (res) => {
          console.log(res, "关联");
          if (res.code == 200) {
            this.getQueryData();
          } else {
            this.$message({
              message: res.msg,
              type: "error",
              duration: 5 * 1000,
            });
          }
        }
      );
    },

    // 获取接口数据的方法
    getQueryData() {
      agv_info_get({
        equipment_id: this.equipment_id,
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          console.log(res, "querydata的值");
          this.querydata = res.data;
        } else {
          this.$message({
            message: res.msg,
            type: "error",
            duration: 5 * 1000,
          });
        }
      });
    },

    // 获取接口数据的方法
    getOperatingRecordData() {
      this.$http(
        agv_record_get({
          equipment_id: this.equipment_id,
        }),
        (res) => {
          console.log(res);
          if (res.code == 200) {
            this.recorddata = res.data;
          } else {
            this.$message({
              message: res.msg,
              type: "error",
              duration: 5 * 1000,
            });
          }
        }
      );
    },

    // 获取证书
    getLicenceData() {
      this.$http(
        agv_licence_get({
          equipment_id: this.equipment_id,
        }),
        (res) => {
          console.log(res);
          if (res.code == 200) {
            this.licencedata = res.data;
          } else {
            this.$message({
              message: res.msg,
              type: "error",
              duration: 5 * 1000,
            });
          }
        }
      );
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((res) => {
          console.log(res);
          done();
        })
        .catch((res) => {
          console.log(res);
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
