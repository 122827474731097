<template>
  <div>
    <el-dialog title="选择车库" :visible.sync="visible_dialog" width="80%" @close="handleClose">

    <el-table :data="queryData.tableData">
        <el-table-column prop="garage_id" label="车库ID"></el-table-column>
        <el-table-column prop="info_name" label="车库名"></el-table-column>
        <el-table-column prop="info_address" label="车库地址"></el-table-column>
        
        <el-table-column label="更多" fixed="right" width="140" align="center">
            <template slot-scope="scope">
            <el-button @click="tpSelectItem(scope.row)">选择</el-button>
            </template>
        </el-table-column>
    </el-table>

    <!--分页组件 不要删除-->
    <div class="layoutBorder">
        <Pagination :total="queryData.total" :page.sync="queryData.currentPage" @pagination="getList()" :limit.sync="queryData.pageSize"/>
    </div>

    </el-dialog>
  </div>
</template>
<script>
import { garage_manage } from "@/api/manage/business/garage/garage.js";
import Pagination from "@/components/Pagination.vue";
export default {
  name: "selectProduct",
    props: ["visible", "pitch_item"],
    created() {
        this.getQueryData() // 定义该函数在页面一加载时就执行
    },
    data() {
        return {
        visible_dialog: null,
            queryData: {
                tableData: [],
                currentPage: 1,
                total: 0,
                pageCount: 0,
                pageSize: 10
            },
        };
    },
    components: { Pagination },
    watch: {
        visible() {
            this.visible_dialog = this.visible;
        },
    },
    methods: {
        getQueryData() {
        this.$http(
            garage_manage({
                "querypage": this.queryData.currentPage, //获取页码
                "pagesize":  10, //每页返回数量
                "keyword": "", //搜索
                "company_status": 0 //0准备中, 1正常对外服务, 2已停止服务
            }),
            (res) => {
            console.log(res);
            if (res.code == 200) {
                console.log(res,"data:success")
                this.queryData.total = res.data.total;
                this.queryData.currentPage = res.data.currentPage;
                this.queryData.pageCount = res.data.pageCount;
                this.queryData.tableData = res.data.result;
            } else {
                console.log(res,"data:fail")
                this.$message({
                message: res.msg,
                type: "error",
                duration: 5 * 1000,
                });
            }
            }
        );
        },
        handleClose() {
            this.$emit("update:visible", false);
        },
        tpSelectItem(items) {
            this.$emit("update:pitch_item", items);
            // console.log(items)
            this.visible_dialog = false
            this.handleClose()
        }
    }
};
</script>